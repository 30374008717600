.general-btn {
  border-radius: 6px;
  min-width: 280px;
  height: 48px;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;

  &__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }

  &__disabled {
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
  }
}

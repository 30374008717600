
.wrapper-services-list{
  padding: 0 16px;

  .services-list{
    border-radius: 10px;
    background-color: white;
    color: black;
    margin-bottom: 10px;

    &__salon{
      display: flex;
      align-items: center;
      padding: 5px 16px;

      &__img{
        height: 50px;
        width: 50px;
        background-color: red;
        border-radius: 50%;
        margin-right: 10px;
      }

      &__name{
        color: black;
      }
    }
  }

  .services-list__service{
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;

    &__category{
      max-width: 50%;
      p{
        margin: 0;
        color: black;
      }
    }

  }

}

.tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  .tab {
    color: #0084F4;
    background-color: white;
    border: solid 1px #0084F4;
    min-width: 200px;
  }

  .active {
    background-color: #0084F4 !important;
    color: white;
    min-width: 200px;
  }
}

.button {
  width: 100%;
  border-radius: 6px;
  min-width: 280px;
  height: 48px;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
}